<template>
  <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <c-card class="cardClassDetailForm" title="작업이력 정보">
        <template slot="card-detail">
          <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
            <c-text
              :editable="false"
              label="작업지시No."
              name="col0"
              v-model="data.col20">
            </c-text>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
            <c-text
              :editable="false"
              label="작업명"
              name="col1"
              v-model="data.col10">
            </c-text>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
            <c-select
              :editable="false"
              type="edit"
              :comboItems="comboItems3"
              itemText="codeName"
              itemValue="code"
              name="col41"
              label="작업유형"
              v-model="data.col41"
            ></c-select>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
            <c-plant
              :editable="false"
              name="plantCd"
              v-model="data.plantCd">
            </c-plant>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
            <c-equip
              :editable="false"
              label="대상설비"
              name="col60"
              v-model="data.col60">
            </c-equip>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
            <c-dept type="edit" :editable="editable" label="위치" name="col5" v-model="data.col5" :isFirstValue="false" />
          </div>
          <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
            <c-dept type="edit" :editable="false" label="정비요청부서" name="col3" v-model="data.col3" />
          </div>
          <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
            <c-text
              :editable="false"
              label="정비요청자"
              name="col19"
              v-model="data.col19">
            </c-text>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
            <c-datepicker
              :editable="editable"
              :required="true"
              type="datetime"
              label="실제 시작일시"
              name="col31"
              v-model="data.col31"
            />
          </div>
          <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
            <c-datepicker
              :editable="editable"
              :required="true"
              type="datetime"
              label="실제 완료일시"
              name="col4"
              v-model="data.col4"
            />
          </div>
          <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
            <c-select
              :editable="editable"
              type="edit"
              :comboItems="comboItems1"
              itemText="codeName"
              itemValue="code"
              name="col11"
              label="고장분류"
              v-model="data.col11"
            ></c-select>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
            <c-select
              :editable="editable"
              type="edit"
              :comboItems="comboItems2"
              itemText="codeName"
              itemValue="code"
              name="col21"
              label="고장코드"
              v-model="data.col21"
            ></c-select>
          </div>
        </template>
      </c-card>
    </div>
    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
      <c-table
        ref="grid1"
        title="작업자"
        :columns="grid1.columns"
        :data="grid1.data"
        :gridHeight="grid1.height"
        :editable="editable&&!disabled"
        :hideBottom="true"
        :isExcelDown="false"
        :filtering="false"
        :columnSetting="false"
        selection="multiple"
        rowKey="userId"
      >
        <template slot="table-button">
          <q-btn-group outline >
            <c-btn v-if="editable&&!disabled" label="추가" icon="add"/>
            <c-btn v-if="editable&&!disabled" label="삭제" icon="remove" />
          </q-btn-group>
        </template>
      </c-table>
    </div>
    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
      <c-table
        ref="grid2"
        title="작업자재"
        :columns="grid2.columns"
        :data="grid2.data"
        :gridHeight="grid2.height"
        :editable="editable&&!disabled"
        :hideBottom="true"
        :isExcelDown="false"
        :filtering="false"
        :columnSetting="false"
        selection="multiple"
        rowKey="userId"
      >
        <template slot="table-button">
          <q-btn-group outline >
            <c-btn v-if="editable&&!disabled" label="추가" icon="add"/>
            <c-btn v-if="editable&&!disabled" label="삭제" icon="remove" />
          </q-btn-group>
        </template>
      </c-table>
    </div>
    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
      <c-table
        ref="grid3"
        title="작업용역"
        :columns="grid3.columns"
        :data="grid3.data"
        :gridHeight="grid3.height"
        :editable="editable&&!disabled"
        :hideBottom="true"
        :isExcelDown="false"
        :filtering="false"
        :columnSetting="false"
        selection="multiple"
        rowKey="userId"
      >
        <template slot="table-button">
          <q-btn-group outline >
            <c-btn v-if="editable&&!disabled" label="추가" icon="add"/>
            <c-btn v-if="editable&&!disabled" label="삭제" icon="remove" />
          </q-btn-group>
        </template>
      </c-table>
    </div>
    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
      <c-table
        ref="grid4"
        title="작업공구"
        :columns="grid4.columns"
        :data="grid4.data"
        :gridHeight="grid4.height"
        :editable="editable&&!disabled"
        :hideBottom="true"
        :isExcelDown="false"
        :filtering="false"
        :columnSetting="false"
        selection="multiple"
        rowKey="userId"
      >
        <template slot="table-button">
          <q-btn-group outline >
            <c-btn v-if="editable&&!disabled" label="추가" icon="add"/>
            <c-btn v-if="editable&&!disabled" label="삭제" icon="remove" />
          </q-btn-group>
        </template>
      </c-table>
    </div>
  </div>
</template>

<script>
// import selectConfig from '@/js/selectConfig';
export default {
  name: 'outernal-info',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        id: '',
      }),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    contentHeight: null,
  },
  data() {
    return {
      comboItems3: [
        {
          code: '1',
          codeName: 'PM(예방보전)',
        },
        {
          code: '2',
          codeName: 'RM(일상보전)',
        },
        {
          code: '3',
          codeName: 'CM(개량보전)',
        },
        {
          code: '4',
          codeName: 'BM(사후보전)',
        },
        {
          code: '5',
          codeName: 'PdM(예측보전)',
        },
      ],
      comboItems1: [
        {
          code: '1',
          codeName: '고장분류 #1',
        },
        {
          code: '2',
          codeName: '고장분류 #2',
        },
      ],
      comboItems2: [
        {
          code: '1',
          codeName: '고장코드 #1',
        },
        {
          code: '2',
          codeName: '고장코드 #2',
        },
      ],
      attachInfo: {
        isSubmit: '',
        taskClassCd: 'OUTER_AUDIT_ATTACH',
        taskKey: '',
      },
      editable: false,
      data: {
        col1: null,
        col2: null,
        col31: '2022-11-22 10:10:00',
        col4: '2022-11-22 13:40:00',
        col19: '관리자',
        col10: '지하저장조 배관 변경작업',
        col20: 'WO-02-001',
        col41: '1',
        col21: '1',
        col11: '1',
        col60: 'EC00000001',
      },
      grid1: {
        columns: [
          {
            name: 'col1',
            field: 'col1',
            label: '작업자',
            align: 'center',
            type: 'user',
            userId: 'userId',
            sortable: false,
          },
          {
            name: 'col2',
            field: 'col2',
            label: '작업시작일',
            align: 'center',
            type: 'date',
            style: 'width: 200px',
            setHeader: true,
            sortable: false,
          },
          {
            name: 'col3',
            field: 'col3',
            label: '시작시간',
            align: 'center',
            type: 'datetime',
            style: 'width: 150px',
            datetimeType: 'time', 
            setHeader: true,
            sortable: false,
          },
          {
            name: 'col4',
            field: 'col4',
            label: '종료시간',
            align: 'center',
            type: 'datetime',
            style: 'width: 150px',
            datetimeType: 'time', 
            setHeader: true,
            sortable: false,
          },
        ],
        data: [],
        height: '250px',
      },
      grid2: {
        columns: [
          {
            name: 'col1',
            field: 'col1',
            label: '자재',
            align: 'center',
            type: 'text',
            sortable: false,
          },
          {
            name: 'col2',
            field: 'col2',
            label: '설명',
            align: 'left',
            type: 'text',
            style: 'width: 300px',
            sortable: false,
          },
          {
            name: 'col3',
            field: 'col3',
            label: '단가',
            type: 'number',
            style: 'width: 100px',
            sortable: false,
          },
          {
            name: 'col4',
            field: 'col4',
            label: '수량',
            type: 'number',
            style: 'width: 80px',
            sortable: false,
          },
          {
            name: 'col5',
            field: 'col5',
            label: '소계',
            type: 'cost',
            style: 'width: 120px',
            sortable: false,
          },
        ],
        data: [],
        height: '250px',
      },
      grid3: {
        columns: [
          {
            name: 'col1',
            field: 'col1',
            label: '업체명',
            align: 'center',
            type: 'vendor',
            sortable: false,
          },
          {
            name: 'col2',
            field: 'col2',
            label: '작업내용',
            align: 'left',
            type: 'text',
            style: 'width: 300px',
            sortable: false,
          },
          {
            name: 'col3',
            field: 'col3',
            label: '투입인원',
            type: 'number',
            style: 'width: 100px',
            sortable: false,
          },
          {
            name: 'col4',
            field: 'col4',
            label: '용역비용',
            type: 'number',
            style: 'width: 140px',
            sortable: false,
          },
        ],
        data: [],
        height: '250px',
      },
      grid4: {
        columns: [
          {
            name: 'col1',
            field: 'col1',
            label: '공구명',
            align: 'center',
            type: 'text',
            sortable: false,
          },
          {
            name: 'col2',
            field: 'col2',
            label: '사용 개수',
            align: 'center',
            type: 'number',
            style: 'width: 140px',
            sortable: false,
          },
        ],
        data: [],
        height: '250px',
      },
      grid5: {
        columns: [
          {
            // 사업장
            name: 'plantName',
            field: 'plantName',
            label: 'LBL0000200',
            align: 'center',
            sortable: true,
            style: 'width:80px',
          },
          {
            // 작업개요
            name: 'workSummary',
            field: 'workSummary',
            label: 'LBL0000211',
            align: 'left',
            sortable: true,
            type: 'link',
            style: 'width:250px',
          },
          {
            // 진행단계
            name: 'swpStepName',
            field: 'swpStepName',
            label: 'LBL0000206',
            align: 'center',
            sortable: true,
            style: 'width:100px',
          },
          {
            // 결재진행단계
            name: 'approvalStatusName',
            field: 'approvalStatusName',
            label: 'LBL0000212',
            align: 'center',
            sortable: true,
            style: 'width:100px',
          },
          {
            // 허가일
            name: 'permitDate',
            field: 'permitDate',
            label: 'LBL0000213',
            align: 'center',
            sortable: true,
            style: 'width:100px',
          },
          {
            // 허가서구분
            name: 'permitTypeName',
            field: 'permitTypeName',
            label: 'LBL0000204',
            align: 'center',
            sortable: true,
            style: 'width:100px',
          },
          {
            // 보충작업
            name: 'supplementWorkTypeNames',
            field: 'supplementWorkTypeNames',
            label: 'LBL0000205',
            align: 'left',
            sortable: true,
            style: 'width:200px',
          },
          {
            // 신청부서
            name: 'applicationDeptName',
            field: 'applicationDeptName',
            label: 'LBL0000202',
            align: 'center',
            sortable: true,
            style: 'width:100px',
          },
          {
            // 발급부서
            name: 'issuedDeptName',
            field: 'issuedDeptName',
            label: 'LBL0000203',
            align: 'center',
            sortable: true,
            style: 'width:100px',
          },
        ],
        data: [],
        height: '150px',
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
  },
  watch: {
  },
  methods: {
    init() {
      // this.editable = this.$route.meta.editable;
      this.editable = false;
      this.grid1.data = [
        {
          col1: '관리자',
          userId: 'U000000001',
          col2: '2022-11-20',
          col3: '14:00',
          col4: '16:30',
        },
        {
          col1: '홍길동',
          userId: 'U000000002',
          col2: '2022-11-20',
          col3: '14:00',
          col4: '16:30',
        },
      ]
      this.grid2.data = [
        {
          col1: '부품 #0012',
          col2: '특정부위에 속한 부품',
          col3: '200000',
          col4: '2',
          col5: '400000',
        },
      ]
      this.grid3.data = [
        {
          col1: 'V232213',
          col2: '작업을 위한 투입',
          col3: '3',
          col4: '1800000',
        },
      ]
      this.grid4.data = [
        {
          col1: '스패너',
          col2: '5',
        },
        {
          col1: '용접기',
          col2: '1',
        },
      ]
      this.grid5.data = [
        {
          "editFlag": null,
          "regUserId": null,
          "regUserName": null,
          "chgUserId": null,
          "chgUserName": null,
          "userDeptCd": null,
          "approvalTypeCd": "APTC000001",
          "approvalStatusCd": "ASC9999999",
          "approvalStatusName": "결재완료",
          "sysApprovalRequestId": "A000000049",
          "plantCdList": null,
          "regDt": "2022-11-25 09:30:10",
          "regDtStr": null,
          "chgDt": "2022-11-25 09:30:10",
          "chgDtStr": null,
          "sopWorkPermitId": "WP00000039",
          "plantCd": "1000",
          "plantName": "사업장1",
          "mdmSopId": "",
          "sopName": "",
          "permitNo": "WPO-2022-00022",
          "swpStepCd": "SS00000005",
          "swpStepName": "승인완료",
          "applicationDeptCd": "D10005",
          "applicationDeptUserName": "생산팀 A / 관리자",
          "applicationDeptName": "생산팀 A",
          "applicationUserId": "U000000001",
          "applicationUserName": "관리자",
          "permitTypeCd": "SPT0000001",
          "permitTypeName": "일반",
          "permitDate": "2022-10-27",
          "workStartTime": "08:00",
          "workEndTime": "17:00",
          "processCd": "P00001-002",
          "processName": "건조-탈수",
          "workLocation": "장소",
          "workManagerId": "U000000001",
          "workManagerName": null,
          "equipmentCd": "EC00000023",
          "equipmentName": "설비 #00023",
          "workSummary": "A동 증설로 인한 고소작업",
          "specialRequirements": "",
          "cancelRemark": "",
          "actionRemark": "",
          "maintenanceDeptCheckUserId": "",
          "maintenanceDeptEntryUserId": "",
          "workCompleteTime": "",
          "workCompleteEntryUserId": "",
          "workCompleteWorkUserId": "",
          "issuedDeptCd": "D10005",
          "issuedDeptUserName": "생산팀 A / 관리자",
          "issuedDeptName": "생산팀 A",
          "issuedUserId": "U000000001",
          "issuedUserName": "관리자",
          "approvalDeptCd": "D10005",
          "approvalUserId": "U000000006",
          "relationCooperation1DeptCd": "",
          "relationCooperation1UserId": "",
          "relationCooperation2DeptCd": "",
          "relationCooperation2UserId": "",
          "vendorCd": "VEN0000000",
          "vendorName": "자체",
          "overWorkFlag": "",
          "psmFlag": "N",
          "sopMocId": null,
          "assessWriteUserId": "U000000001",
          "assessApprovalUserId": "U000000006",
          "swpWorkRequestCd": null,
          "swpWorkRequestName": null,
          "swpWorkRequestType": null,
          "returnRemark": "",
          "vendorFlag": null,
          "supplementWorkTypeNames": "고소",
          "supWorks": null,
          "deleteSupWorks": null,
          "checkResults": null,
          "deleteCheckResults": null,
          "gases": null,
          "gasChecks": null,
          "deleteGasChecks": null,
          "deleteGasCheckVals": null,
          "maps": null,
          "deleteMaps": null,
          "workers": null,
          "deleteWorkers": null,
          "assessments": null,
          "deleteAssessments": null,
          "overs": null,
          "deleteOvers": null,
          "protectiveGears": null,
          "deleteProtectiveGears": null
        },
        {
          "editFlag": null,
          "regUserId": null,
          "regUserName": null,
          "chgUserId": null,
          "chgUserName": null,
          "userDeptCd": null,
          "approvalTypeCd": "APTC000001",
          "approvalStatusCd": "ASC9999999",
          "approvalStatusName": "결재완료",
          "sysApprovalRequestId": "A000000041",
          "plantCdList": null,
          "regDt": "2022-11-25 09:30:10",
          "regDtStr": null,
          "chgDt": "2022-11-25 09:30:10",
          "chgDtStr": null,
          "sopWorkPermitId": "WP00000035",
          "plantCd": "1000",
          "plantName": "사업장1",
          "mdmSopId": "SOP0000001",
          "sopName": "베어링 하우징 교체2",
          "permitNo": "WPO-2022-00018",
          "swpStepCd": "SS00000005",
          "swpStepName": "승인완료",
          "applicationDeptCd": "D10005",
          "applicationDeptUserName": "생산팀 A / 관리자",
          "applicationDeptName": "생산팀 A",
          "applicationUserId": "U000000001",
          "applicationUserName": "관리자",
          "permitTypeCd": "SPT0000005",
          "permitTypeName": "화기",
          "permitDate": "2022-10-20",
          "workStartTime": "08:00",
          "workEndTime": "17:00",
          "processCd": "P00001-001",
          "processName": "RM-T3 공정",
          "workLocation": "2층",
          "workManagerId": null,
          "workManagerName": null,
          "equipmentCd": "EC00000074",
          "equipmentName": "설비 #00074",
          "workSummary": "A3구역 밀폐공간 가스제거 작업",
          "specialRequirements": "",
          "cancelRemark": "",
          "actionRemark": "",
          "maintenanceDeptCheckUserId": "",
          "maintenanceDeptEntryUserId": "",
          "workCompleteTime": "",
          "workCompleteEntryUserId": "",
          "workCompleteWorkUserId": "",
          "issuedDeptCd": "D10005",
          "issuedDeptUserName": "생산팀 A / 관리자",
          "issuedDeptName": "생산팀 A",
          "issuedUserId": "U000000001",
          "issuedUserName": "관리자",
          "approvalDeptCd": "D10005",
          "approvalUserId": "U000000026",
          "relationCooperation1DeptCd": "",
          "relationCooperation1UserId": "",
          "relationCooperation2DeptCd": "",
          "relationCooperation2UserId": "",
          "vendorCd": "VEN0000000",
          "vendorName": "자체",
          "overWorkFlag": "",
          "psmFlag": "N",
          "sopMocId": null,
          "assessWriteUserId": "",
          "assessApprovalUserId": "",
          "swpWorkRequestCd": null,
          "swpWorkRequestName": null,
          "swpWorkRequestType": null,
          "returnRemark": null,
          "vendorFlag": null,
          "supplementWorkTypeNames": "밀폐공간",
          "supWorks": null,
          "deleteSupWorks": null,
          "checkResults": null,
          "deleteCheckResults": null,
          "gases": null,
          "gasChecks": null,
          "deleteGasChecks": null,
          "deleteGasCheckVals": null,
          "maps": null,
          "deleteMaps": null,
          "workers": null,
          "deleteWorkers": null,
          "assessments": null,
          "deleteAssessments": null,
          "overs": null,
          "deleteOvers": null,
          "protectiveGears": null,
          "deleteProtectiveGears": null
        },
      ]
    },
  }
};
</script>