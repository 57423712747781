var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row" }, [
    _c(
      "div",
      { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" },
      [
        _c(
          "c-card",
          {
            staticClass: "cardClassDetailForm",
            attrs: { title: "작업이력 정보" },
          },
          [
            _c("template", { slot: "card-detail" }, [
              _c(
                "div",
                {
                  staticClass: "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2",
                },
                [
                  _c("c-text", {
                    attrs: {
                      editable: false,
                      label: "작업지시No.",
                      name: "col0",
                    },
                    model: {
                      value: _vm.data.col20,
                      callback: function ($$v) {
                        _vm.$set(_vm.data, "col20", $$v)
                      },
                      expression: "data.col20",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2",
                },
                [
                  _c("c-text", {
                    attrs: { editable: false, label: "작업명", name: "col1" },
                    model: {
                      value: _vm.data.col10,
                      callback: function ($$v) {
                        _vm.$set(_vm.data, "col10", $$v)
                      },
                      expression: "data.col10",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2",
                },
                [
                  _c("c-select", {
                    attrs: {
                      editable: false,
                      type: "edit",
                      comboItems: _vm.comboItems3,
                      itemText: "codeName",
                      itemValue: "code",
                      name: "col41",
                      label: "작업유형",
                    },
                    model: {
                      value: _vm.data.col41,
                      callback: function ($$v) {
                        _vm.$set(_vm.data, "col41", $$v)
                      },
                      expression: "data.col41",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2",
                },
                [
                  _c("c-plant", {
                    attrs: { editable: false, name: "plantCd" },
                    model: {
                      value: _vm.data.plantCd,
                      callback: function ($$v) {
                        _vm.$set(_vm.data, "plantCd", $$v)
                      },
                      expression: "data.plantCd",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2",
                },
                [
                  _c("c-equip", {
                    attrs: {
                      editable: false,
                      label: "대상설비",
                      name: "col60",
                    },
                    model: {
                      value: _vm.data.col60,
                      callback: function ($$v) {
                        _vm.$set(_vm.data, "col60", $$v)
                      },
                      expression: "data.col60",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2",
                },
                [
                  _c("c-dept", {
                    attrs: {
                      type: "edit",
                      editable: _vm.editable,
                      label: "위치",
                      name: "col5",
                      isFirstValue: false,
                    },
                    model: {
                      value: _vm.data.col5,
                      callback: function ($$v) {
                        _vm.$set(_vm.data, "col5", $$v)
                      },
                      expression: "data.col5",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2",
                },
                [
                  _c("c-dept", {
                    attrs: {
                      type: "edit",
                      editable: false,
                      label: "정비요청부서",
                      name: "col3",
                    },
                    model: {
                      value: _vm.data.col3,
                      callback: function ($$v) {
                        _vm.$set(_vm.data, "col3", $$v)
                      },
                      expression: "data.col3",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2",
                },
                [
                  _c("c-text", {
                    attrs: {
                      editable: false,
                      label: "정비요청자",
                      name: "col19",
                    },
                    model: {
                      value: _vm.data.col19,
                      callback: function ($$v) {
                        _vm.$set(_vm.data, "col19", $$v)
                      },
                      expression: "data.col19",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2",
                },
                [
                  _c("c-datepicker", {
                    attrs: {
                      editable: _vm.editable,
                      required: true,
                      type: "datetime",
                      label: "실제 시작일시",
                      name: "col31",
                    },
                    model: {
                      value: _vm.data.col31,
                      callback: function ($$v) {
                        _vm.$set(_vm.data, "col31", $$v)
                      },
                      expression: "data.col31",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2",
                },
                [
                  _c("c-datepicker", {
                    attrs: {
                      editable: _vm.editable,
                      required: true,
                      type: "datetime",
                      label: "실제 완료일시",
                      name: "col4",
                    },
                    model: {
                      value: _vm.data.col4,
                      callback: function ($$v) {
                        _vm.$set(_vm.data, "col4", $$v)
                      },
                      expression: "data.col4",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2",
                },
                [
                  _c("c-select", {
                    attrs: {
                      editable: _vm.editable,
                      type: "edit",
                      comboItems: _vm.comboItems1,
                      itemText: "codeName",
                      itemValue: "code",
                      name: "col11",
                      label: "고장분류",
                    },
                    model: {
                      value: _vm.data.col11,
                      callback: function ($$v) {
                        _vm.$set(_vm.data, "col11", $$v)
                      },
                      expression: "data.col11",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2",
                },
                [
                  _c("c-select", {
                    attrs: {
                      editable: _vm.editable,
                      type: "edit",
                      comboItems: _vm.comboItems2,
                      itemText: "codeName",
                      itemValue: "code",
                      name: "col21",
                      label: "고장코드",
                    },
                    model: {
                      value: _vm.data.col21,
                      callback: function ($$v) {
                        _vm.$set(_vm.data, "col21", $$v)
                      },
                      expression: "data.col21",
                    },
                  }),
                ],
                1
              ),
            ]),
          ],
          2
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" },
      [
        _c(
          "c-table",
          {
            ref: "grid1",
            attrs: {
              title: "작업자",
              columns: _vm.grid1.columns,
              data: _vm.grid1.data,
              gridHeight: _vm.grid1.height,
              editable: _vm.editable && !_vm.disabled,
              hideBottom: true,
              isExcelDown: false,
              filtering: false,
              columnSetting: false,
              selection: "multiple",
              rowKey: "userId",
            },
          },
          [
            _c(
              "template",
              { slot: "table-button" },
              [
                _c(
                  "q-btn-group",
                  { attrs: { outline: "" } },
                  [
                    _vm.editable && !_vm.disabled
                      ? _c("c-btn", { attrs: { label: "추가", icon: "add" } })
                      : _vm._e(),
                    _vm.editable && !_vm.disabled
                      ? _c("c-btn", {
                          attrs: { label: "삭제", icon: "remove" },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          2
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" },
      [
        _c(
          "c-table",
          {
            ref: "grid2",
            attrs: {
              title: "작업자재",
              columns: _vm.grid2.columns,
              data: _vm.grid2.data,
              gridHeight: _vm.grid2.height,
              editable: _vm.editable && !_vm.disabled,
              hideBottom: true,
              isExcelDown: false,
              filtering: false,
              columnSetting: false,
              selection: "multiple",
              rowKey: "userId",
            },
          },
          [
            _c(
              "template",
              { slot: "table-button" },
              [
                _c(
                  "q-btn-group",
                  { attrs: { outline: "" } },
                  [
                    _vm.editable && !_vm.disabled
                      ? _c("c-btn", { attrs: { label: "추가", icon: "add" } })
                      : _vm._e(),
                    _vm.editable && !_vm.disabled
                      ? _c("c-btn", {
                          attrs: { label: "삭제", icon: "remove" },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          2
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" },
      [
        _c(
          "c-table",
          {
            ref: "grid3",
            attrs: {
              title: "작업용역",
              columns: _vm.grid3.columns,
              data: _vm.grid3.data,
              gridHeight: _vm.grid3.height,
              editable: _vm.editable && !_vm.disabled,
              hideBottom: true,
              isExcelDown: false,
              filtering: false,
              columnSetting: false,
              selection: "multiple",
              rowKey: "userId",
            },
          },
          [
            _c(
              "template",
              { slot: "table-button" },
              [
                _c(
                  "q-btn-group",
                  { attrs: { outline: "" } },
                  [
                    _vm.editable && !_vm.disabled
                      ? _c("c-btn", { attrs: { label: "추가", icon: "add" } })
                      : _vm._e(),
                    _vm.editable && !_vm.disabled
                      ? _c("c-btn", {
                          attrs: { label: "삭제", icon: "remove" },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          2
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" },
      [
        _c(
          "c-table",
          {
            ref: "grid4",
            attrs: {
              title: "작업공구",
              columns: _vm.grid4.columns,
              data: _vm.grid4.data,
              gridHeight: _vm.grid4.height,
              editable: _vm.editable && !_vm.disabled,
              hideBottom: true,
              isExcelDown: false,
              filtering: false,
              columnSetting: false,
              selection: "multiple",
              rowKey: "userId",
            },
          },
          [
            _c(
              "template",
              { slot: "table-button" },
              [
                _c(
                  "q-btn-group",
                  { attrs: { outline: "" } },
                  [
                    _vm.editable && !_vm.disabled
                      ? _c("c-btn", { attrs: { label: "추가", icon: "add" } })
                      : _vm._e(),
                    _vm.editable && !_vm.disabled
                      ? _c("c-btn", {
                          attrs: { label: "삭제", icon: "remove" },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          2
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }